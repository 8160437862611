import { types } from "../action";

// ** Initial State
const initialState = {
  loading: true,
  loadingFilterCampania: true,
  dataFilterProp: {},
  regiones: [],
  regionSelect: null,
  agencias: [],
  agenciaSelect: null,
  agenciasFilter: [],
  campanias: [],
  campaniaSelect: null,
  usuarios: [],
  usuariosFilter: [],
  usuarioSelect:null,
  filterCampania: [],
  filterCampaniaSelect: [],
  queryFilter:[]
};

const FilterGeneralState = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING:
      return { ...state, loading: action.loading };
    case types.SET_LOADING_FILTER_CAMPANIA:
      return { ...state, loadingFilterCampania: action.loadingFilterCampania };
    case types.SET_DATA_FILTER_PROP:
      return { ...state, dataFilterProp: action.dataFilterProp };
    case types.SET_REGIONES:
      return { ...state, regiones: action.regiones };

    case types.SET_REGION_SELECT:
      return { ...state, regionSelect: action.regionSelect };
    case types.SET_AGENCIAS:
      return {
        ...state,
        agencias: action.agencias,
        agenciasFilter: action.agencias,
      };
    case types.SET_AGENCIA_SELECT:
      return { ...state, agenciaSelect: action.agenciaSelect };
    case types.SET_FILTER_AGENCIA:
      return { ...state, agenciasFilter: action.agenciasFilter };
    case types.SET_CAMPANIAS:
      return { ...state, campanias: action.campanias };
    case types.SET_CAMPANIA_SELECT:
      return { ...state, campaniaSelect: action.campaniaSelect };  
    case types.SET_FILTER_CAMPANIA_SELECT:
      return { ...state, filterCampaniaSelect: action.filterCampaniaSelect };  
    case types.SET_USUARIOS:
      return {
        ...state,
        usuarios: action.usuarios,
        usuariosFilter: action.usuarios,
      };

    case types.SET_USUARIO_SELECT:
      return { ...state, usuarioSelect: action.usuarioSelect };
    case types.SET_FILTER_USUARIO:
      return { ...state, usuariosFilter: action.usuariosFilter };
    case types.SET_FILTER_CAMPANIA:
      return { ...state, filterCampania: action.filterCampania };
    case types.SET_QUERY_FILTER:
      return { ...state, queryFilter: action.queryFilter };
    default:
      return { ...state };
  }
};
export default FilterGeneralState;
