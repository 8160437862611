// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

import { FirebaseAppProvider } from "reactfire";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
/* import {
  persistQueryClient,
  createSyncStoragePersister,
} from '@tanstack/query-persist-client'; */
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

/* import { ReactQueryDevtools } from "@tanstack/react-query-devtools"; */

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import firebaseConfig from "./firebaseConfig";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

//import registerServiceWorker from "./registerServiceWorker";
//import registerServiceWorker from "./registerServiceWorker";

// Create a client

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //staleTime: Infinity,
     /*  refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 1 * 60 * 60 * 1000,
      staleTime: 1 * 60 * 60 * 1000,
      retry: 1, */
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
    
    //maxAge= {Infinity}
  >
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <LazyApp />

            <ToastContainer newestOnTop />
          </ThemeContext>
        </Suspense>
      </Provider>
    </FirebaseAppProvider>
   {/*  <ReactQueryDevtools initialIsOpen={true} /> */}
  </PersistQueryClientProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//registerServiceWorker();
