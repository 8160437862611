import { eq, maxBy, toNumber, toString } from "lodash";
import platform from "platform";
import { VERSION_K } from "../@core/constants";
import Hashids from "hashids";
import * as Icons from "react-feather";
import * as IconsFA from "react-icons/fa";
import { capitalize } from "lodash";
import Avatar from "@components/avatar";
import { components } from "react-select";
import { ErrorListToast, ErrorToast } from "@ui-elements/components/alerts";

import { toast, Slide } from "react-toastify";
import { X } from "react-feather";
const CryptoJS = require("crypto-js");

const secret = "4Pl!c4t1v0 1NN3S";
const alfabeto =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

const hashids = new Hashids(secret, 8);

export const calculateTimeToMidnight = () => {
  const now = new Date();
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1, // Tomorrow at 00:00:00
    0,
    0,
    0
  );
  return midnight - now;
};

export const typeField = (type) => {
  if (
    eq(type, "INTEGER") ||
    eq(type, "DOUBLE") ||
    eq(type, "DECIMAL") ||
    eq(type, "BIGINT")
  ) {
    return "number";
  } else if (eq(type, "DATE")) {
    return "date";
  }
  return "string";
};

export const sleep = (milliseconds) => {
  return new Promise((r) => {
    setTimeout(() => {
      r(true);
    }, milliseconds);
  });
};

export const encryptArray = (array) => {
  const jsonString = JSON.stringify(array);
  return CryptoJS.SHA256(jsonString).toString(CryptoJS.enc.Hex);
};

export const encryptUrl = (texto) => {
  // Encriptar utilizando AES-256-CBC y salida en hexadecimal
  var encrypted = CryptoJS.AES.encrypt(texto, secret);
  return {
    ciphertext: encrypted.ciphertext.toString(),
    salt: encrypted.salt.toString(),
  };
};

export const decryptUrl = (textoEncriptado, salt) => {
  var decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(textoEncriptado),
      salt: CryptoJS.enc.Hex.parse(salt),
    },
    secret
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
  //-------------------------------------------------------------------------
};

export const getUrlParameter = (name, search) => {
  //console.log("search", search);
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

  var results = regex.exec(search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const geocodeByLtnLng = (lat, lng) => {
  const geocoder = new window.google.maps.Geocoder();
  const currentLocation = new window.google.maps.LatLng(lat, lng);

  const { OK } = window.google.maps.GeocoderStatus;
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: currentLocation }, (results, status) => {
      if (status !== OK) {
        return reject(status);
      }
      return resolve(results);
    });
  });
};

export const isMerryChristmas = () => {
  const m = new Date().getMonth();
  return m === 11;
};

export const getHours = (date) => {
  const h = date.getHours(),
    m = date.getMinutes();

  return (h > 12 ? h : "0" + h) + ":" + (m > 12 ? m : "0" + m);
};

export const detectar = () => {
  let dispositivo = {
    esMovil: false,
    esTablet: false,
    esAndroid: false,
    esiPhone: false,
    esiPad: false,
    esOrdenador: false,
    esWindows: false,
    esLinux: false,
    esMac: false,
  };
  //navigator.userAgent.toLowerCase().match(/mobile/)
  var isMobile = false;

  if (
    /Android/i.test(navigator.userAgent) ||
    /iPhone/i.test(navigator.userAgent) ||
    /iPad/i.test(navigator.userAgent) ||
    /iPod/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }
  dispositivo.esMovil = isMobile;
  return dispositivo;
};

export const convertMenu = (data) => {
  var ID_KEY = "id";
  var PARENT_KEY = "parent_id";
  var CHILDREN_KEY = "children";

  var tree = [],
    childrenOf = {};
  var item, id, parentId;

  for (var i = 0, length = data.length; i < length; i++) {
    item = data[i];
    id = item[ID_KEY];
    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId !== 0) {
      // init its parent's children object
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children object
      childrenOf[parentId].push(item);
    } else {
      tree.push(item);
    }
  }
  //order by orden and children recursively

  //console.log("tree", tree);
  return ordenarMenuPorPropiedad(tree, "orden");
};

export const ordenarMenuPorPropiedad = (arbol, propiedad = "orden") => {
  // Primero, ordena el nivel actual si es un array
  if (Array.isArray(arbol)) {
    arbol.sort((a, b) => (a[propiedad] || 0) - (b[propiedad] || 0));
  }

  // Luego, recorre cada elemento y aplica la misma función a sus hijos si existen
  arbol.forEach((elemento) => {
    for (let key in elemento) {
      if (Array.isArray(elemento[key])) {
        ordenarMenuPorPropiedad(elemento[key], propiedad);
      }
    }
  });

  return arbol;
};

export const hasChildren = (item) => {
  return (
    item.children.length > 0 ||
    (item.children.some(hasChildren) && item.children.length > 0)
  );
};

export const collectIds = (item, result) => {
  // Agregar el id actual si parent_id es 0 o si children no está vacío
  if (item.parent_id === 0 || item.children.length > 0) {
    result.push(item);
  }

  // Realizar una búsqueda en profundidad en los hijos
  item.children.forEach((child) => collectIds(child, result));
};

export const convertMenu2 = (data) => {
  var ID_KEY = "id";
  var PARENT_KEY = "parent_id";
  var CHILDREN_KEY = "children";

  var tree = [],
    childrenOf = {};
  var item, id, parentId;

  for (var i = 0, length = data.length; i < length; i++) {
    item = data[i];
    id = item[ID_KEY];
    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId !== 0) {
      // init its parent's children object
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children object
      childrenOf[parentId].push(item);
    } else {
      item.children = null;
      tree.push(item);
    }
  }

  return ordenarMenuPorPropiedad(tree, "orden");
};

export const filterIcon = (val, size = 20) => {
  let IconTag = Icons[val];
  IconTag = IconTag === undefined ? Icons["Circle"] : Icons[val];
  return <IconTag size={size} />;
};

export const filterIconFA = (val, size = 24, className = "") => {
  let IconTag = IconsFA[val];
  IconTag = IconTag === undefined ? IconsFA["FaCircle"] : IconsFA[val];
  ////console.log("IconTag", IconTag);
  return <IconTag size={size} className={className} />;
};

export const encodeHashids = (numero) => {
  return hashids.encode(numero);
};

export const GuestsComponent = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex flex-wrap align-items-center">
        <Avatar className="my-0 mr-1" size="sm" img={data.avatar} />
        <div>{fullNameCapitalice(data.label)}</div>
      </div>
    </components.Option>
  );
};

export const GuestsUserComponent = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex flex-wrap align-items-center">
        {/* <Avatar className="my-0 mr-1" size="sm" img={data.avatar} /> */}
        <div>{fullNameCapitalice(data.usuario)}</div>
      </div>
    </components.Option>
  );
};

export const decodeHashids = (hash) => {
  const hashr = hashids.decode(hash);
  if (hashr.length === 0) {
    return null;
  }
  return hashr[0];
};

export const encrypt = (content) =>
  CryptoJS.AES.encrypt(JSON.stringify({ content }), VERSION_K).toString();

export const decrypt = (crypted) =>
  JSON.parse(
    CryptoJS.AES.decrypt(crypted, VERSION_K).toString(CryptoJS.enc.Utf8)
  ).content;

export const formatDocumento = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
};

export const getBrowser = () => platform.name;

export const getSO = () =>
  `${platform.os.family} ${platform.os.version} ${platform.os.architecture}`;

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

export const formatFecha = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const getAge = (date) =>
  new Date(new Date() - new Date(date)).getFullYear() - 1970;

export const isBirthday = (date) => {
  const dateb = new Date(date);
  const month = dateb.getMonth() + 1; //months from 1-12
  const day = dateb.getDate() + 1;

  const dateObj = new Date();
  const month2 = dateObj.getMonth() + 1; //months from 1-12
  const day2 = dateObj.getDate();

  const rs = month === month2 && day2 === day;

  return rs;
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const getVersionK = () => localStorage.getItem("versionK");

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export function calcularFechaMinima(fecha) {
  return toString(fecha) == "" ? new Date().toJSON().slice(0, 10) : fecha;
}

export function usarZoom(value, obj, start, end) {
  if (value) {
    start = start == null || start === 0 ? 70 : start;
    end = end == null || end === 0 ? 100 : end;
    obj.dataZoom = [
      {
        type: "slider",
        start: start,
        end: end,
      },
      {
        type: "inside",
        start: start,
        end: end,
      },
    ];

    return obj;
  }
  return obj;
}

export function comasNumero(num) {
  var value_str = String(num);
  var num_parts = value_str.split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  ////console.log("comas_numero",num,num_parts.join("."));
  return num_parts.join(".");
}

export function addDays(date, days) {
  //const date = new Date(fecha);
  date.setDate(date.getDate() + days);
  return date;
}

export function timeDifference(current, previous) {
  ////console.log("timeDifference", current, previous)

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  elapsed = elapsed < 0 ? 0 : elapsed;

  if (elapsed < msPerMinute) {
    return "Hace " + Math.round(elapsed / 1000) + " segundos";
  } else if (elapsed < msPerHour) {
    return "Hace " + Math.round(elapsed / msPerMinute) + " minutos";
  } else if (elapsed < msPerDay) {
    return "Hace " + Math.round(elapsed / msPerHour) + " horas";
  } else if (elapsed < msPerMonth) {
    return "Hace aprox. " + Math.round(elapsed / msPerDay) + " días";
  } else if (elapsed < msPerYear) {
    return "Hace aprox. " + Math.round(elapsed / msPerMonth) + " meses";
  } else {
    return "Aprox. hace " + Math.round(elapsed / msPerYear) + " años";
  }
}

export function fullNameCapitalice(val) {
  const arr = val.split(" ");
  let text = "";
  let i = arr.length - 1;
  arr.forEach((el) => {
    text = capitalize(arr[i]) + " " + text;
    i--;
  });
  return text;
}

export function nFormatter(num, digits) {
  var retorno;
  var negativo = false;

  if (num < 0) {
    num = Math.abs(num);
    negativo = true;
  }

  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    //{ value: 1E9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  //var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  //var rx = /\B(?=(\d{3})+(?!\d))+$/g;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  retorno = (num / si[i].value).toFixed(digits);
  retorno = comasNumero(retorno) + si[i].symbol;
  //retorno = (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  if (negativo) {
    retorno = "-" + retorno;
  }
  return retorno;
}

export function converNumber(num) {
  //console.log("num", num);
  if (!isNaN(parseFloat(num)) && isFinite(num)) {
    //console.log("isNumber", num);
    return toNumber(num);
  } else {
    return num;
  }
}

export function orderByHead(obj) {
  //this.state.count--;
  ////console.log("obj", obj);
  let count = obj.count;
  count--;

  //var obj = document.getElementById(id);
  //let icon = "fas fa-arrows-";
  switch (count) {
    case 2:
      obj = {
        title: obj.title,
        iconHead: "fas fa-arrow-up",
        order: "asc",
        count: count,
        label: obj.label,
      };
      //this.props.onOrdenIcon(this.state.stringOrderHead)
      break;
    case 1:
      obj = {
        title: obj.title,
        iconHead: "fas fa-arrow-down",
        order: "desc",
        count: count,
        label: obj.label,
      };

      break;
    case 0:
      obj = {
        title: obj.title,
        iconHead: "fas fa-arrows-alt-v",
        order: "",
        count: 3,
        label: obj.label,
      };
      break;
    default:
      obj = {
        title: obj.title,
        iconHead: "fas fa-arrows-alt-v",
        order: "",
        count: 0,
        label: obj.label,
      };
    //this.props.onOrdenIcon(this.state.stringOrderHead)
  }
  return obj;
}

export function objPuestosById(puestos, arr) {
  if (puestos != null) {
    var puestosArr = puestos.split("-");
    var objsPuestos = [];

    puestosArr.forEach(function (value1, index1, array1) {
      arr?.forEach((value, index, array) => {
        if (array[index].value == value1) {
          objsPuestos.push(array[index]);
        }
      });
    });
    return objsPuestos;
  }
  return [];
}

export function cadenaIdPuestos(puestosSelected) {
  var puesto = "";
  if (puestosSelected.length != 0) {
    //puesto = this.puestosSelected.codpue

    puestosSelected.forEach((value, index, array) => {
      if (index == puestosSelected.length - 1) {
        puesto = puesto + puestosSelected[index].codpue;
      } else {
        puesto = puesto + puestosSelected[index].codpue + "-";
      }
    });
  }
  return puesto;
}

export function isAdmin(authorities) {
  let admin = false;
  authorities.forEach((value) => {
    if (value.authority.includes("ROLE_ADMIN")) {
      admin = true;
    }
  });
  return admin;
}

export function validarTypeString(type) {
  var result = false;
  if (type.includes("CHAR")) {
    result = true;
  }
  if (type.includes("VARCHAR")) {
    result = true;
  }
  if (type.includes("LONGVARCHAR")) {
    result = true;
  }

  return result;
}

export function validarTypeNumero(type) {
  var result = false;
  if (type.includes("BIGINT")) {
    result = true;
  }
  if (type.includes("NUMERIC")) {
    result = true;
  }
  if (type.includes("DECIMAL")) {
    result = true;
  }
  if (type.includes("INTEGER")) {
    result = true;
  }
  if (type.includes("SMALLINT")) {
    result = true;
  }
  if (type.includes("FLOAT")) {
    result = true;
  }
  if (type.includes("REAL")) {
    result = true;
  }
  if (type.includes("DOUBLE")) {
    result = true;
  }
  return result;
}

export const toolbox = {
  showTitle: true,
  feature: {
    dataView: {
      show: true,
      readOnly: false,
      title: "Data",
      lang: ["Vista de datos", "Cerrar", "Actualizar"],
      optionToContent: function (opt) {
        ////console.log("opt",opt);
        var axisData = opt.xAxis[0].data;
        var series = opt.series;

        var table =
          '<button onclick="exportTableToExcelFun()"  class="btn  btn-icon mg-r-5 mg-b-10"  style="cursor: pointer;background-color: #207245;color: white"> <i class="fa fa-file-excel-o" aria-hidden="true"></i></button>' +
          '<table id="tablaDetallePlus" class="table table-striped" ><thead><tr>' +
          "<th>Categoría</th>";

        for (var j = 0; j < series.length; j++) {
          table += "<th>" + series[j].name + "</th>";
        }
        table += "</tr></thead>";

        table += "<tbody><tr>";
        for (var i = 0; i < axisData.length; i++) {
          table += "<td>" + axisData[i] + "</td>";
          for (var j = 0; j < series.length; j++) {
            if (series[j].name == "Tendencia") {
              var tam = series[j].data[0].length - 1;
              var valor = series[j].data[i][tam];
              table += "<td>" + comasNumero(valor) + "</td>";
            } else {
              var valor = series[j].data[i];
              table += "<td>" + comasNumero(valor) + "</td>";
            }
          }
          table += "</tr>";
        }
        table += "</tbody></table>";
        return table;
      },
    },
    restore: {
      show: true,
      title: "Restaurar",
    },
    saveAsImage: {
      show: true,
      title: "Guardar",
      name: "Visualización",
    },
  },
};

export function usaToolbox(value) {
  return value ? this.toolbox : {};
}

export function genFormatter(series, stack) {
  return function (param) {
    var sum = 0;
    series.forEach(function (item) {
      if (item.stack == stack) {
        sum += item.data[param.dataIndex];
      }
    });

    return nFormatterDecimal(sum, 3);
  };
}

export function nFormatterDecimal(num, digit) {
  var retorno, aux;
  var negativo = false;

  if (num < 0) {
    num = Math.abs(num);
    negativo = true;
  }

  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    //{ value: 1E9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  //var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  //var rx = /\B(?=(\d{3})+(?!\d))+$/g;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  aux = num / si[i].value;
  var num_dec = 0;
  for (var j = digit; j > 0; j--) {
    var comodin = Math.pow(10, j - 1) - 1;
    if (aux > comodin) {
      num_dec = digit - j;
      break;
    }
  }

  retorno = aux.toFixed(num_dec);
  retorno = comasNumero(retorno) + si[i].symbol;
  //retorno = (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  if (negativo) {
    retorno = "-" + retorno;
  }

  //debugger;
  return retorno;
}

export function exportTableToExcelFun(id) {
  id = typeof id !== "undefined" ? id : "tablaDetallePlus";

  var downloadLink;
  var dataType = "application/vnd.ms-excel";
  //"tablaDetalle"
  var tableSelect = document.getElementById(id);
  var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

  // Specify file name
  var filename = "tablaDetalle.xls";

  // Create download link element
  downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    var blob = new Blob(["ufeff", tableHTML], {
      type: dataType,
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = "data:" + dataType + ", " + tableHTML;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
  }
}

export function usaZoom(value, obj, start, end) {
  if (value) {
    start = start == null || start == 0 ? 70 : start;
    end = end == null || end == 0 ? 100 : end;
    obj.dataZoom = [
      {
        type: "slider",
        start: start,
        end: end,
      },
      {
        type: "inside",
        start: start,
        end: end,
      },
    ];

    return obj;
  }
  return obj;
}

export function rotateEtiqueta(etiquetax) {
  //console.log("etiquetas,", etiquetax);
  var max = maxBy(etiquetax, function (o) {
    return o.length;
  });
  ////console.log("min",min);
  if (max.length >= 7 && etiquetax.length > 12) {
    return -90;
  } else {
    return 0;
  }
}

export function labelTrue(index, obarr, enabled) {
  var retorna = false;
  ////console.log("enabled",enabled);
  if (enabled) {
    obarr.forEach(function (val) {
      if (val.index == index) {
        retorna = true;
      }
    });
  }
  ////console.log("retorna",retorna);
  return retorna;
}

export function getOffset(arr) {
  if (arr.length > 2) {
    return Number(arr[2]);
  }
  return 0;
}

export function nFormatter_1(num, digits) {
  var retorno;
  var negativo = false;

  if (num < 0) {
    num = Math.abs(num);
    negativo = true;
  }

  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    //{ value: 1E9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  retorno = parseFloat(Math.round((num / si[i].value) * 100) / 100).toFixed(
    digits
  ); //.replace(rx, "$1"));
  if (negativo) {
    retorno = "-" + retorno;
  }
  return retorno;
}

export function groupBy(xs, key) {
  /* var reduce =  xs.reduce(function(rv, x) {
       (rv[x[key]] = rv[x[key]] || []).push(x);
       return rv;
   }, {});
   return Object.keys(reduce);*/
  var obj = [];
  xs.forEach(function (value1, index) {
    obj.push(value1[key] == undefined ? 0 : value1[key]);
  });
  ////console.log("groupBy", obj);
  return obj;
}

export const groupBy2 = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function tipoVisualizar(value) {
  if (value.length > 3) {
    if (value[3].includes("column") || value[3].includes("line")) {
      return value[3];
    } else {
      return "column";
    }
  } else {
    return "column";
  }
}

export function tipoVisualizar2(value) {
  if (value.length > 3) {
    if (value[3].includes("bar") || value[3].includes("line")) {
      return value[3];
    } else {
      return "bar";
    }
  } else {
    return "bar";
  }
}

export function indexEjeY(value) {
  if (value.length > 3) {
    if (value[3].includes("column")) {
      return 0;
    }
    if (value[3].includes("line")) {
      return 1;
    }
  } else {
    return 0;
  }
}
export function indexEjeY2(value) {
  if (value.length > 3) {
    if (value[3].includes("bar")) {
      return 0;
    }
    if (value[3].includes("line")) {
      return 1;
    }
  } else {
    return 0;
  }
}
export function labelEje2(value, etiquetasx, etiquetasy) {
  var obj = {
    normal: {
      show: true,
      position: "top",
      formatter: function (value, index) {
        return nFormatterDecimal(value.value, 0);
      },
    },
  };

  if (value.length > 3) {
    if (value[3].includes("bar")) {
      if (etiquetasx) {
        ////console.log("estan activadas las etiquetas bar");
        return obj;
      } else {
        ////console.log("no estan activadas las etiquetas");
        obj.normal.show = false;
        return obj;
      }
    }
    if (value[3].includes("line")) {
      if (etiquetasy) {
        ////console.log("estan activadas las etiquetas line");
        return obj;
      } else {
        ////console.log("no estan activadas las etiquetas");
        obj.normal.show = false;
        return obj;
      }
    }
  } else {
    return obj;
  }
}

export function labelEje(value, etiquetasx, etiquetasy) {
  var obj = {
    normal: {
      show: true,
      position: "top",
      formatter: function (value, index) {
        return nFormatterDecimal(value.value, 0);
      },
    },
  };

  if (value.length > 3) {
    if (value[3].includes("column")) {
      if (etiquetasx) {
        ////console.log("estan activadas las etiquetas bar");
        return obj;
      } else {
        ////console.log("no estan activadas las etiquetas");
        obj.normal.show = false;
        return obj;
      }
    }
    if (value[3].includes("line")) {
      if (etiquetasy) {
        ////console.log("estan activadas las etiquetas line");
        return obj;
      } else {
        ////console.log("no estan activadas las etiquetas");
        obj.normal.show = false;
        return obj;
      }
    }
  } else {
    return obj;
  }
}

export function redonderarMillones(num) {
  var div = num / 1000000;
  return parseFloat(Math.round(div * 100) / 100).toFixed(2);
}

const ToastMsjContent = ({ title, errors, color, icon }) => (
  <div>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={icon} />
        <h6 className="toast-title font-weight-bold">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <ul className="list-unstyled">
        {errors.map((e, index) => (
          <li key={index}>* {e}</li>
        ))}
      </ul>
    </div>
  </div>
);

export function successHttp(response) {
  if (response?.data?.msj) {
    toast.success(
      <ToastMsjContent
        title={`Bien`}
        errors={response.data.msj}
        color={"success"}
        icon={<X size={12} />}
      />,
      {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 2000,
      }
    );
  }
}

export function errorHttp(error) {
  const data = error.response.data.msj;
  if (Array.isArray(data)) {
    toast(<ErrorListToast title={"Error!"} message={data} color={"danger"} />, {
      autoClose: false,
    });
  } else {
    toast(<ErrorToast title={"Error!"} message={data} color={"danger"} />, {
      autoClose: false,
    });
  }
}

export const customSort = (rows, selector, direction) => {
  return rows.sort((a, b) => {
    // use the selector to resolve your field names by passing the sort comparitors
    const aField = selector(a).toLowerCase();
    const bField = selector(b).toLowerCase();

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === "desc" ? comparison * -1 : comparison;
  });
};
